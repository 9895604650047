@use '../../styles/base';

div.event-submission-container {
  width: 100%;

  div.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .event-submission-container-item {
    &.active {
      display: block;
    }

    &.inactive {
      display: none;
    }
  }

  .event-submission-action-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: base.$space-medium;

    button:not(:last-child) {
      margin-right: base.$space-medium;
    }
  }

  .user-form-container {
    display: flex;
    flex-direction: row;

    &>div {
      margin: base.$space-large;
      flex: 1;
    }

    .login-form {
      min-width: unset;

      .register-link {
        display: none;
      }

      .forget-password-link {
        display: none;
      }
    }

    .registration-form {
      min-width: unset;
    }
  }

  .event-confirmation {
    min-height: calc(100vh - 55px - 139px);

    @include base.phone {
      min-height: calc(100vh - 44px - 184px);
    }

    button:not(:last-child) {
      margin-right: base.$space-medium;
    }
  }

  .tiptap-editor {
    .ProseMirror {
      min-height: 300px;
      padding: 12px;
      margin-top: 12px;
      border: 2px dashed #ccc;
      border-radius: 6px;

      >*+* {
        margin-top: 0.75em;
      }

      p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
      }

      ul,
      ol {
        padding: 0 1rem;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 1.1;
      }

      code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
      }

      pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      hr {
        margin: 1rem 0;
      }

      a {
        text-decoration: underline;
      }

      blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
      }

      iframe {
        border: 8px solid #000;
        border-radius: 4px;
        min-width: 200px;
        min-height: 200px;
        display: block;
        outline: 0px solid transparent;
      }

      div[data-youtube-video] {
        cursor: move;
        padding-right: 24px;
      }

      .ProseMirror-selectednode iframe {
        transition: outline 0.15s;
        outline: 6px solid #ece111;
      }

      .tableWrapper {
        padding: 1rem 0;
        overflow-x: auto;
      }

      .resize-cursor {
        cursor: ew-resize;
        cursor: col-resize;
      }

      table {
        border-collapse: collapse;
        margin: 0;
        overflow: hidden;
        table-layout: fixed;
        width: 100%;

        td,
        th {
          border: 2px solid #ced4da;
          box-sizing: border-box;
          min-width: 1em;
          padding: 3px 5px;
          position: relative;
          vertical-align: top;

          >* {
            margin-bottom: 0;
          }
        }

        th {
          background-color: #f1f3f5;
          font-weight: bold;
          text-align: left;
        }

        .selectedCell:after {
          background: rgba(200, 200, 255, 0.4);
          content: "";
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          pointer-events: none;
          position: absolute;
          z-index: 2;
        }

        .column-resize-handle {
          background-color: #adf;
          bottom: -2px;
          position: absolute;
          right: -2px;
          pointer-events: none;
          top: 0;
          width: 4px;
        }

        p {
          margin: 0;
        }
      }
    }

    .tiptap-editor-menubar {
      button {
        background-color: black;
        border: none;
        padding: 10px;
        width: 41px;
        height: 41px;
        display: inline-block;
        overflow: hidden;
        text-align: center;
        color: rgb(255, 255, 255);
        font-size: 16px;
        line-height: 42px;
        vertical-align: top;
        line-height: 1;
        margin: 0px;
        font-size: 20.56px;

        &.is-active {
          color: rgb(91, 217, 116);
        }
      }
      & > *:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      & > *:last-child{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .tiptap-editor-menubar + div {
      max-height: 900px;
      overflow-y: scroll;
    }
  }
}